import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import './aboutUs.css';
import aboutus from '../../Assets/aboutUs.jpg'
function Aboutus() {
  return (
    <section className="aboutus-bg" >
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <img src={aboutus} className="img-fluid aboutImage" alt="aboutus"></img>
          </Col>
          <Col lg={6} className= "p-4">
            {/* <h2 className="title">About Us</h2> */}
            <p className="text"><span>Often</span> micro-innovative ideas taking shape in the classrooms, and schools hold promising solutions to the stickiest issues of education, only if these micro-innovations are recognised and documented. Teacher Innovation Award (TIA), a first-of-its-kind awards in India recognises teachers implementing the most effective innovative ideas that bear no monetary expenditure or investment. The ideas are scaled up to millions of teachers and thousands of schools facing similar challenges. The education leadership of the country honours innovative teachers to propel substantial and sustainable upliftment of education.</p>
          </Col>
        </Row>
      </ Container>
    </section>
  )
}
export default Aboutus;