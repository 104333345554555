import React from "react";
import { Link, NavLink } from 'react-router-dom';
import { Button, Container, Row, Col } from "react-bootstrap";
import Copyright from "./Copyright";
import './Footer.css';

function Footer() {
  return (
    <>
      <section className="footer">
        <Container>
          <Row>
            <Col lg="12">
              <div className="get-started">
                <h2 className="title ">Ready to Get Started?</h2>
                <div className="d-flex flex-wrap justify-content-center" style={{gap: "1rem"}}>
                <a href="https://ziiei.com/teacherinnovationaward/" target="_blank" rel="noreferrer"><Button variant="getStarted">TI Award</Button></a>
                <NavLink to="/ti-award"><Button variant="getStarted">Previous Awards</Button></NavLink>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <h2 className="sub-title">About</h2>
              <p className="text">Zero Investment Innovation for Education Initiatives (ZIIEI) - We are India’s widest teacher-first education innovation movement, focused on how education is delivered in classrooms and equipping teachers with teaching strategies that promote experiential learning activities.</p>
            </Col>
            <Col lg="4">
              <div >
                <h2 className="sub-title">Quick Links</h2>
                <ul className="list-unstyled">
                  <li><Link to="/our-story">Our Story</Link></li>
                  <li><Link to="/impact" className="nav-link">Impact</Link></li>
                  <li><Link to="/ti-award" className="nav-link"  >TI Award</Link></li>
                </ul>
              </div>
            </Col>
            <Col lg="4">
              {/* <Form className="d-flex flex-column justify-content-center h-100">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder="Email" size="lg" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Button variant="subscribe">Subscribe</Button>
                </Form.Group>
              </Form> */}
            </Col>
          </Row>
        </Container>
      </section>
      <Copyright />
    </>
  )
}

export default Footer