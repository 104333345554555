import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import './aboutUs.css';
// import AboutImage from '../../Assets/about.png';
// import aboutPlay from '../../Assets/about-play.svg';
function Aboutus() {
  return (
    <section className="storyAboutus">
      <Container>
        <Row className="align-items-center">
          <Col lg="7">
            <h2 className="title">About <span> Us</span></h2>
            <p className="text">Zero Investment for Innovative Education programme , ZIIEI works in collaboration with teachers spread across the country, who join us for learning the need and importance of innovation in education, identifying newer solutions, and harnessing the power of zero-investment innovations in education. All our efforts and strategies - more than dozens of activities across the programme - have emerged through this process of identifying good practices of teachers and the greatest impact they are making.</p>
          </Col>
          <Col lg="5">
            <div className="aboutImageSection">
              {/* <img src={AboutImage} className="img-fluid aboutImage" alt="aboutImage"/>
              <a href="https://youtu.be/Q1qiGhShDiQ" target="_blank"><img src={aboutPlay} className="playImage" alt="playImage"/></a> */}
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/Q1qiGhShDiQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </Col>
        </Row>
      </ Container>
    </section >
  )
}
export default Aboutus;