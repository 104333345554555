import React from 'react'
import Banner from './Banner/Banner'
import ImpactStories from './ImapactStories/ImpactStories'
import Focus from './Focus/Focus'
import Definition from './Definition/Definition'
import Report from './Report/Report'
import Footer from '../Footer/Footer'
import Testimonials from '../Home/Testimonials/Testimonials'
function Impact() {
  return (
    <div>
        <Banner/>
        <ImpactStories/>
        <Focus/>
        <Definition/>
        <Report/>
        <Testimonials/>
        <Footer/>
    </div>
  )
}

export default Impact