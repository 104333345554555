import React from "react";
import Banner from "./Banner/Banner";
import ItStrategies from "./ItStrategies/ItStrategies";
import TeachersInnovationAwards from "./TeachersInnovationAwards/TeachersInnovationAwards";
import Idea from "./Ideas/Ideas";
import Testimonials from "./Testimonials/Testimonials";
import Footer from "../Footer/Footer";
function Home() {
  return (
    <>
      <Banner />
      <ItStrategies />
      <TeachersInnovationAwards />
      <Idea />
      <Testimonials />
      <Footer />
    </>
  )
}
export default Home