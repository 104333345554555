import React from "react";
import { Container} from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Congrats.css';
// import RightArrow from '../../Assets/RightArrow.svg'
// import LeftArrow from '../../Assets/LeftArrow.svg'
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowRight } from 'react-icons/bs';
import Image1 from '../../Assets/tia gallery/1.png';
import Image2 from '../../Assets/tia gallery/2.png';
import Image3 from '../../Assets/tia gallery/3.png';
import Image4 from '../../Assets/tia gallery/4.png';
import Image5 from '../../Assets/tia gallery/5.png';
import Image6 from '../../Assets/tia gallery/6.png';

function Congrats() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  function CustomRightArrow({ onClick }) {
    function handleClick() {
      // // do whatever you want on the right button click
      // // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      // <img src={RightArrow}
      //   onClick={handleClick}
      //   aria-label="Go to next slide"
      //   className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      // />
      <BsArrowRight
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      />
    );
  }

  function CustomLeftArrow({ onClick }) {
    function handleClick() {
      // // do whatever you want on the right button click
      // // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      // <img src={LeftArrow}
      //   onClick={handleClick}
      //   aria-label="Go to next slide"
      //   className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      // />
      <BsArrowLeft
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      />
    );
  }

  return (
    <>
      <section className="tiaCongrats">
        <Container fluid>
          <Carousel
            itemClass="tiaCongratsCarousel"
            responsive={responsive}
            infinite
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            <div>
              <img src={Image1} alt="Image1" className="sliderImage" />
            </div>
            <div>
              <img src={Image2} alt="Image2" className="sliderImage" />
            </div>
            <div>
              <img src={Image3} alt="Image3" className="sliderImage" />
            </div>
            <div>
              <img src={Image4} alt="Image4" className="sliderImage" />
            </div>
            <div>
              <img src={Image5} alt="Image5" className="sliderImage" />
            </div>
            <div>
              <img src={Image6} alt="Image6" className="sliderImage" />
            </div>
          </Carousel>

        </Container>
      </section>
    </>
  )
}
export default Congrats;