import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Header/Header";
import './Banner.css';
// import Arrow from '../../Assets/arrow.svg';
function Banner() {
  return (
    <section className="impactBanner">
      <Header />
      <Container fluid>
        <Row className="justify-content-center">
          <Col xxl="5" lg="6">
            <div className="banner-content">
              <h1 className="title">Our Impact</h1>
              <p className="text">A teacher can inspire hope , ignite the imagination , and instill a love for learning , contributing to the future of Education</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default Banner