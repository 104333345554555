import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import previousdata from '../../Assets/previousdata.png';
import './previous.css'

function Previous() {
  return (
    <>
      <section className="previous">
        <Container>
          <Row>
            <Col>
              <h2 className="title">
                Previous Year
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={previousdata} className="img-fluid" alt="previousyear"></img>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default Previous;