import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './component/Home/Home';
import Impact from './component/Impact/Impact';
import TIA from './component/TIA/TIA';
import Story from './component/Story/Story';
import ScrollToTop from './component/ScrollToTop/ScrollToTop';

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
        <Routes>
          <Route exact path="/impact" element={<Impact />} />
        </Routes>
        <Routes>
          <Route exact path="/our-story" element={<Story/>} />
        </Routes>
        <Routes>
          <Route exact path="/ti-award" element={<TIA />} />
        </Routes>
      </Router>
    
    </div>
  );
}

export default App;
