import React from 'react'
import './Report.css';
import { Row, Col, Container, Button } from 'react-bootstrap';
// import { BsArrowRight } from 'react-icons/bs';
// import { Link } from 'react-router-dom';
import benchmarkingReport from '../../Assets/reports/Benchmarking Report_FINAL_EY.pdf';
import FinalReportZIIEI from '../../Assets/reports/Final Report_ZIIEI M&E_2018-19.pdf';
import ProcessReportTISS from '../../Assets/reports/ZIIEI Process Report_TISS.pdf';
// import rectangle from '../../Assets/Rectangle 305.svg';
import Ey from '../../Assets/ey.png';
import Kpmg from '../../Assets/kpmg.png';
import Tiss from '../../Assets/tiss.png';

function Report() {
  return (
    <section className="report-Banner">
      <Container >
        <Row>
          <Col>
            <h2 className='title'> Impact <span>Reports</span></h2>
            <Row>
              <Col lg={4}>
                <div className='reportSection'>
                  <img src={Ey} className="img-fluid mx-auto d-block" alt='ey'/>
                  <h3 className='subTitle'>Benchmarking Report by <span>EY</span></h3>
                  <a href={benchmarkingReport} target="_blank" rel="noreferrer" className='reportDownload img-fluid'><Button variant='detail'> View Details</Button></a>
                </div>
              </Col>
              <Col lg={4}>
                <div className='reportSection'>
                  <img src={Kpmg} className="img-fluid mx-auto d-block" alt='kpmg' />
                  <h3 className='subTitle'>Impact Report by <span>KPMG</span> </h3>
                  <a href={FinalReportZIIEI} target="_blank" rel="noreferrer" className='reportDownload img-fluid'><Button variant='detail'> View Details</Button></a>
                </div>
              </Col>
              <Col lg={4}>
                <div className='reportSection'>
                  <img src={Tiss} className="img-fluid mx-auto d-block" alt='tiss' />
                  <h3 className='subTitle'>Programme Impact Report by <span>TISS, Mumbai</span></h3>
                  <a href={ProcessReportTISS} target="_blank" rel="noreferrer" className='reportDownload img-fluid'><Button variant='detail'> View Details</Button></a>
                </div>
              </Col>
            </Row>
            {/* <div className='d-flex justify-content-center'>
              <Button variant='report'>More <BsArrowRight className="rightArrow" /></Button>
            </div> */}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Report 