import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import './ItStrategies.css';
function ItStrategies() {
  return (
    <section className="itStrategies">
      <Container>
        <Row>
          <Col>
            <h2 className="title"><span>Innovative Teaching</span> Strategies</h2>
            <p className="text">Zero Investment Innovation for Education Initiatives (ZIIEI) - We are the India’s widest teacher-first education innovation movement, focused on how education is delivered in classrooms and equipping teachers with teaching strategies that promote experiential learning activities. Our strength lies in creating integral pedagogies, using data and evidence-based interventions to improve students’ learning outcome curve. We systematically support teachers with teaching strategies, techniques and technology to improve the quality of teaching; raise academic standards, and accelerate progress of all the students. To support education for all, the programme recognises innovative ideas of teaching and learning activities and integrated education.</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default ItStrategies