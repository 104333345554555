import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './sta.css';
// import stavideo from '../../Assets/stavideo.svg';
import finger from '../../Assets/finger.png';
import upload from '../../Assets/upload.png';
import Doc from '../../Assets/Doc.png';
import pencil from '../../Assets/pencil.png';
// import stepsPlay from '../../Assets/stepsPlay.svg';

function STA() {
  return (
    <>
      <section className="sta">
        <Container>
          <Row>
            <Col>
              <h2 className="title">
                Steps To Apply
              </h2>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={6}>
              <h3 className="process-title">
                Steps To Register for TIA
              </h3>
              <Row>
                <Col lg={6}>
                  <div className="stepProcess">
                    <img src={finger} className="img-fluid" alt="step1"></img>
                    <div className="steps">
                      <h3 className="stepTitle">Step1 </h3>
                      <span className="stepText"> Click on the Registration Link</span>
                      {/* <Button variant="step">Click on the link</Button> */}
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="stepProcess">
                    <img src={Doc} className="img-fluid" alt="step2"></img>
                    <div className="steps">
                      <h3 className="stepTitle">
                        Step 2
                      </h3>
                      <span className="stepText"> Fill in the form with mentioned details</span>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="stepProcess">
                    <img src={upload} className="img-fluid" alt="step3"></img>
                    <div className="steps">
                      <h3 className="stepTitle">
                        Step 3
                      </h3>
                      <span className="stepText"> Submit your idea with attached documents or images or videos</span>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="stepProcess">
                    <img src={pencil} className="img-fluid" alt="step4"></img>
                    <div className="steps">
                      <h3 className="stepTitle">
                        Step 4
                      </h3>
                      <span className="stepText"> Fill in your profile details and detailed idea</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              {/* <div className="stepVideo">
                <a href="https://youtu.be/zcgASjWUpoQ" target="_blank">
                <img src={stavideo} className="img-fluid"></img>
                <img src={stepsPlay} className="img-fluid playImage" alt="playImage"></img>
                </a>
              </div> */}
              <div className="video-frame">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/zcgASjWUpoQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </Col>
          </Row>
        </Container>

      </section>
    </>
  )
}
export default STA;