import React from 'react'
import Footer from '../Footer/Footer'
import Aboutus from './Aboutus/Aboutus'
import Banner from './Banner/Banner'
import GoodNumbers from './GoodNumbers/GoodNumbers'
import Timeline from './Timeline/Timeline'

function Story() {
  return (
    <div>
        <Banner/>
        <Aboutus/>
        <Timeline/>
       <GoodNumbers/>
        <Footer/>
    </div>
  )
}

export default Story