import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './awards.css';
// import dummypic from '../../Assets/dummy.png'
// import RightArrow from '../../Assets/RightArrow.svg'
// import LeftArrow from '../../Assets/LeftArrow.svg'
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowRight } from 'react-icons/bs';
import SaritaSingh from '../../Assets/awardee/SaritaSingh.png';
import BalwantSingh from '../../Assets/awardee/BalwantSingh.png';
import Munesh from '../../Assets/awardee/Munesh.png';
import ShekharChandrakantK from '../../Assets/awardee/ShekharChandrakantK.png';
import KiranBarla from '../../Assets/awardee/KiranBarla.png';

function Awards() {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1200 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1200, min: 768 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  function CustomRightArrow({ onClick }) {
    function handleClick() {
      // // do whatever you want on the right button click
      // // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      // <img src={RightArrow}
      //   onClick={handleClick}
      //   aria-label="Go to next slide"
      //   className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      // />
      <BsArrowRight
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      />
    );
  }

  function CustomLeftArrow({ onClick }) {
    function handleClick() {
      // // do whatever you want on the right button click
      // // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      // <img src={LeftArrow}
      //   onClick={handleClick}
      //   aria-label="Go to next slide"
      //   className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      // />
      <BsArrowLeft
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      />
    );
  }

  return (
    <>
      <section className="awards">
        <Container>
          <Row>
            <Col>
              <h2 className="title">
                Awardees
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Carousel itemClass="OurAwardeeCarousel"
                responsive={responsive}
                infinite
                ssr={true}
                arrows
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
                keyBoardControl
              >

                <div className='awardSection'>
                  <img src={SaritaSingh} className="awardImage" alt="SaritaSingh"></img>
                  <h3 className="awardName">Sarita Singh</h3>
                  <p className="awardYear">
                    Govt. Primary School,
                    Parichhipur, Odisha
                  </p>
                </div>

                <div className='awardSection'>
                  <img src={BalwantSingh} className="awardImage" alt="BalwantSingh"></img>
                  <h3 className="awardName">Balwant Singh</h3>
                  <p className="awardYear">
                    Middle School
                    Shanoge, Jammu
                  </p>
                </div>

                <div className='awardSection'>
                  <img src={Munesh} className="awardImage" alt="Munesh"></img>
                  <h3 className="awardName">Munesh</h3>
                  <p className="awardYear">
                    R.P.V.V. School Sector A-10,
                    Narela Delhi - 110040
                  </p>
                </div>

                <div className='awardSection'>
                  <img src={ShekharChandrakantK} className="awardImage" alt="ShekharChandrakantK"></img>
                  <h3 className="awardName">Shekhar Chandrakant K</h3>
                  <p className="awardYear">
                    Z P High School & Jr.
                    College Pimpalgaaon Raja,
                    Maharashtra
                  </p>
                </div>

                <div className='awardSection'>
                  <img src={KiranBarla} className="awardImage" alt="KiranBarla"></img>
                  <h3 className="awardName">Kiran Barla</h3>
                  <p className="awardYear">
                    Vivekananda Kendra
                    Vidyalaya, Arunachal
                    Pradesh
                  </p>
                </div>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default Awards;