import React from "react";
import Aboutus from "./Aboutus/Aboutus";
import Awards from "./Awards/Awards";
import Banner from "./Banner/Banner";
import GoodNumbers from "./GoodNumbers/GoodNumbers";
import STA from "./STPSAPPLY/STA";
import Community from "./Community/Community";
import Previous from "./Previous/Previous";
// import Submit from "./Submit/Submit";
import Footer from "../Footer/Footer";
import Testimonials from "../Home/Testimonials/Testimonials";
import Congrats from "./Congrats/Congrats";
import OurHandbooks from "./OurHandbooks/OurHandbooks";


function TIA() {
    return (
        <>
            <Banner />
            <Aboutus />
            <STA />
            <GoodNumbers />
            <OurHandbooks />
            <Congrats />
            <Awards />
            <Community />
            <Previous />
            <Testimonials />
            {/* <Submit /> */}
            <Footer />
        </>
    )
}
export default TIA;