import React from 'react'
import { Col, Row, Container } from 'react-bootstrap';
import './Focus.css';
import Goal from '../../Assets/Goal.svg'
import Book from '../../Assets/Book.svg'
import Time from '../../Assets/Time.svg'
import Group117389 from '../../Assets/Group117389.png'
function Focus() {
  return (
    <section className='impactFocus'>
      <Container>
        <Row>
          <h2 className='title'>Our <span>focus</span></h2>
        </Row>
        <Row>
          <Col lg={3}>
            <div className='text-center'>
              <img src={Goal} className="img-fluid focusImage" alt='goal' />
              <h2 className='sub-title'>Future Envisioning</h2>
              <p className='text'>Due to lack of right exposure and knowledge, students often have less understanding of the importance of identifying their career goals. With the help of this innovation, teacher helps them discover their own potentials and then decide their career goals accordingly.</p>
            </div>
          </Col>
          <Col lg={3}>
            <div className='text-center'>
              <img src={Book} className="img-fluid focusImage" alt='book' />
              <h2 className='sub-title'>Self Learning</h2>
              <p className='text'>Self learning is a necessary life skill for effective lifelong learning and, if developed from an early age, children are able to enhance their learning capability as well as develop better decision-making, planning and execution skills.</p>
            </div>
          </Col>
          <Col lg={3}>
            <div className='text-center'>
              <img src={Time} className="img-fluid focusImage" alt='time'/>
              <h2 className='sub-title'>Improving Attendance</h2>
              <p className='text'>Despite being enrolled in the school, many students frequently miss their classes due to which government schools continue to fare poorly in the learning outcomes. To reverse this trend, the innovation 'Improving Attendance' provides child-centric ways to stay focused.</p>
            </div>
          </Col>
          <Col lg={3} >
            <div className='text-center'>
              <img src={Group117389} className="img-fluid focusImage" alt='games' />
              <h2 className='sub-title'>Learning Through Games</h2>
              <p className='text'>It is often seen that students begin to fear subjects like Mathematics and Science. If these subjects are taught through games, kids will be able to assimilate the topics better.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Focus