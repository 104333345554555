import { React } from "react";
import { Nav, Navbar, Container, Button } from "react-bootstrap";
import './Header.css';
import Logo from '../Assets/logo.png';
import HdfcLogo from '../Assets/hdfc-logo.svg';
import LogoSeprater from '../Assets/Rectangle 212.svg';
import { NavLink } from 'react-router-dom';

function Header() {

  // const [isActive, setIsActive] = useState(false);
  // const handleClick = event => {
  //   setIsActive(current => !current);
  //   event.currentTarget.classList.toggle('active');
  // }

  return (
    <Container>
      <Navbar bg="light" variant="light" expand="lg">
        <Container>
          <Navbar.Brand>
            <img
              src={Logo}
              width="65"
              height="64"
              className="d-inline-block align-top"
              alt="logo"
            />
          </Navbar.Brand>
          <Navbar.Brand>
            <img
              src={LogoSeprater}
              height="60"
              className="align-middle"
              alt="LogoSeprater"
            />
          </Navbar.Brand>
          <Navbar.Brand className="me-auto">
            <img
              src={HdfcLogo}
              width="120"
              height="46"
              className="d-inline-block align-top"
              alt="HdfcLogo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
            <Nav>
              <NavLink to="/" className="nav-link">Home</NavLink>
              <NavLink to="/our-story" className="nav-link">Our Story</NavLink>
              <NavLink to="/impact" className="nav-link">Impact</NavLink>
              <NavLink to="/ti-award" className="nav-link"  >TI Award</NavLink>
            </Nav>
            <a href="https://ziiei.com/teacherinnovationaward/" target="_blank" rel="noreferrer"><Button variant="yellow" className="login-btn">Login</Button></a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  )
}

export default Header