import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './ImapactStories.css'
// import RightArrow from '../../Assets/RightArrow.svg';
// import LeftArrow from '../../Assets/LeftArrow.svg';
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowRight } from 'react-icons/bs';
import SoniaRaniChouhan from '../../Assets/impactstories/SoniaRaniChouhan.jpg';
import NAnuradha from '../../Assets/impactstories/NAnuradha.jpg';
import RajaFatima from '../../Assets/impactstories/RajaFatima.jpg';
import SanjayKumar from '../../Assets/impactstories/SanjayKumar.jpg';
import ShrikantPathak from '../../Assets/impactstories/ShrikantPathak.jpg';
import AshutoshDubey from '../../Assets/impactstories/AshutoshDubey.jpg';
import JyotiRawat from '../../Assets/impactstories/JyotiRawat.jpg';
import ChampaSingh from '../../Assets/impactstories/ChampaSingh.jpg';

function ImpactStories() {


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1368 },
      items: 4
    },
    custom:{
      breakpoint:{max:1368, min:768},
      items:3
    },
    tablet: {
      breakpoint: { max: 768, min: 425 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 425, min: 0 },
      items: 1
    }
  };
  function CustomRightArrow({ onClick }) {
    function handleClick() {
      // // do whatever you want on the right button click
      // // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      // <img src={RightArrow}
      //   onClick={handleClick}
      //   aria-label="Go to next slide"
      //   className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      // />
      <BsArrowRight
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      />
    );
  }

  function CustomLeftArrow({ onClick }) {
    function handleClick() {
      // // do whatever you want on the right button click
      // // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      // <img src={LeftArrow}
      //   onClick={handleClick}
      //   aria-label="Go to next slide"
      //   className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      // />
      <BsArrowLeft
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      />
    );
  }

  return (
    <section className='impactStories'>
      <Container>
        <Row>
          <Col>
            <div className=''>
              <h2 className='title'>Our Impact Stories</h2>
            </div>
            <Carousel itemClass="impactStoriesCarousel"
              responsive={responsive}
              infinite
              ssr={true}
              arrows
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
              keyBoardControl
            >
              <div>
                <img className='image' src={SoniaRaniChouhan} alt="SoniaRaniChouhan"></img>
                <div className='content'>
                  <h2 className='ideaName'>Chitrakatha Navachar</h2>
                  <p className='teacherName'>Sonia Rani Chouhan</p>
                  <p className='schoolName'>Junior High school teacher, Muradabad, uttar pradesh</p>
                </div>
              </div>
              <div>
                <img className='image' src={NAnuradha} alt="NAnuradha"></img>
                <div className='content'>
                  <h2 className='ideaName'>Learning Through Games</h2>
                  <p className='teacherName'>N.Anuradha</p>
                  <p className='schoolName'>Thiru. Vi. Ka. Higher Sec. School Chennai, Tamil Nadu</p>
                </div>
              </div>
              <div>
                <img className='image' src={RajaFatima} alt="RajaFatima"></img>
                <div className='content'>
                  <h2 className='ideaName'>Saral Angrezi Adhigam</h2>
                  <p className='teacherName'>Raja Fatima</p>
                  <p className='schoolName'></p>
                </div>
              </div>
              <div>
                <img className='image' src={SanjayKumar} alt="SanjayKumar"></img>
                <div className='content'>
                  <h2 className='ideaName'>Science Park</h2>
                  <p className='teacherName'>Sanjay Kumar</p>
                  <p className='schoolName'>Govt. Model High School Sector 49D, Chandigarh</p>
                </div>
              </div>
              <div>
                <img className='image' src={ShrikantPathak} alt="ShrikantPathak"></img>
                <div className='content'>
                  <h2 className='ideaName'>Search The Missing</h2>
                  <p className='teacherName'>Shrikant Pathak</p>
                  <p className='schoolName'>UPS Bhadhawa, Mirzapur, Up</p>
                </div>
              </div>
              <div>
                <img className='image' src={AshutoshDubey} alt="AshutoshDubey"></img>
                <div className='content'>
                  <h2 className='ideaName'>बाल सांसद</h2>
                  <p className='teacherName'>Ashutosh Dubey</p>
                  <p className='schoolName'></p>
                </div>
              </div>
              <div>
                <img className='image' src={JyotiRawat} alt="JyotiRawat"></img>
                <div className='content'>
                  <h2 className='ideaName'>लेखन-कार्य</h2>
                  <p className='teacherName'>Jyoti Rawat</p>
                  <p className='schoolName'></p>
                </div>
              </div>
              <div>
                <img className='image' src={ChampaSingh} alt="ChampaSingh"></img>
                <div className='content'>
                  <h2 className='ideaName'>सामुदायिक सहभागिता</h2>
                  <p className='teacherName'>Champa Singh</p>
                  <p className='schoolName'>Junior High School, Gorkhpur, Uttar Pradesh</p>
                </div>
              </div>
            </Carousel>

          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ImpactStories