import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './community.css';

function Community() {
  return (
    <>
      <section className="community">
        <Container>
          <Row>
            <Col>
              <h2 className="title">
                Join a community of 2M empowered and equipped teachers
              </h2>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default Community