import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Ideas.css';
// import RightArrow from '../../Assets/RightArrow.svg'
// import LeftArrow from '../../Assets/LeftArrow.svg'
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowRight } from 'react-icons/bs';
import Idea1 from '../../Assets/Mask Group 65.png';
import Idea2 from '../../Assets/Mask Group 66.png';
import Idea3 from '../../Assets/Mask Group 67.png';

function Idea() {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 525 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 525, min: 0 },
      items: 1
    }
  };
  function CustomRightArrow({ onClick }) {
    function handleClick() {
      // // do whatever you want on the right button click
      // // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      // <img src={RightArrow}
      //   onClick={handleClick}
      //   aria-label="Go to next slide"
      //   className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      // />
      <BsArrowRight
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      />
    );
  }

  function CustomLeftArrow({ onClick }) {
    function handleClick() {
      // // do whatever you want on the right button click
      // // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      // <img src={LeftArrow}
      //   onClick={handleClick}
      //   aria-label="Go to next slide"
      //   className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      // />
      <BsArrowLeft
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      />
    );
  }

  return (
    <section className="idea">
      <Container fluid>
        <Row>
          <Col xl="2" lg="2">
            <div className="d-flex flex-column justify-content-center h-100">
              <h2 className="title">Ideas</h2>
              <p className="text">We envision bringing transformation</p>
            </div>
          </Col>
          <Col xl="10" lg="10">
            <Carousel
              itemClass="ideaCarousel"
              responsive={responsive}
              infinite
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
            >
              <div className="px-2 text-center">
                <img className='slider-image' src={Idea1} alt="idea1"></img>
                <h2 className="slider-title">Interview Of Professionals</h2>
                <p className="slider-text">Laying emphasis on future envisioning, the activity gives an opportunity to students to interview different professionals.</p>
              </div>
              <div className="px-2 text-center">
                <img className='slider-image' src={Idea2} alt="idea1"></img>
                <h2 className="slider-title">How Heavy Is My Bag?</h2>
                <p className="slider-text">Students' school bags are measured and the teacher suggests ways to reduce the weight of the school bags.</p>
              </div>
              <div className="px-2 text-center">
                <img className='slider-image' src={Idea3} alt="idea1"></img>
                <h2 className="slider-title">Search The Missing</h2>
                <p className="slider-text">This idea prevents unnecessary absenteeism among students and makes the parents aware of their wards' absence.</p>
              </div>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Idea