import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Facebook from '../Assets/facebook.svg';
import Twitter from '../Assets/twitter.svg';
import Instagram from '../Assets/instagram.svg';
import './Footer.css';

function Copyright() {
  return (
    <section className="copyright">
      <Container>
        <Row>
          <Col>
            <div className="d-flex justify-content-evenly  copyright-statement ">
              <p className="text">© Copyright. Sri Aurobindo Society. All Rights Reserved</p>
              <ul className="list-inline mb-0 footer-icon justify-content-center">
                <li className="list-inline-item"><a href="https://www.facebook.com/ZIIEISAS" target="_blank" rel="noreferrer"><img src={Facebook} alt="facebook"></img></a></li>
                <li className="list-inline-item"><a href="https://twitter.com/SAS_ZIIEI" target="_blank" rel="noreferrer"
                ><img src={Twitter} alt="twitter"></img></a></li>
                <li className="list-inline-item"><a href="https://www.instagram.com/sas_ziiei/" target="_blank" rel="noreferrer"><img src={Instagram} alt="instagram"></img></a></li>
              </ul>
            </div>            
          </Col>
        </Row>
      </Container>

    </section>
  )
}

export default Copyright