import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import './TeachersInnovationAwards.css';
import TiaImage2 from '../../Assets/tia-image1.png';
import TiaImage1 from '../../Assets/tia-image2.png';
import EllipseBlue from '../../Assets/ellipse-blue.svg';
import EllipseYellow from '../../Assets/ellipse-yellow.svg';
function TeachersInnovationAwards() {
  return (
    <section className="tia">
      <Container>
        <Row className="align-items-end">
          <Col xl="7" lg="6">
            <h2 className="title">Teachers Innovation <span>Awards</span> - TIA</h2>
            <p className="text">We envision bringing transformation in education. Who else has the potential to do this than the very pillars of nation building? Teacher Innovation Award - TIA, is a first-of-its kind initiative in India that recognises and honours teachers for their most effective zero-investment innovations in education. This education innovation award promotes education innovation ideas. The ripple effect of recognition reinforces the positive impact on the teaching community across the country.</p>
            <div className="d-flex apply-button justify-content-center">
              <a href="https://ziiei.com/teacherinnovationaward/" target="_blank" rel="noreferrer" style={{zIndex: "9"}}><Button variant="tiaBlue">Apply for TIA</Button></a>
              <NavLink to="/ti-award"><Button variant="tiaBlue">Previous Awards</Button></NavLink>
            </div>
          </Col>
          <Col xl="5" lg="6" className="tia-images">
            <div className="TiaImage1">
              <img src={TiaImage1} alt="study" className="img-fluid ms-auto d-block baseImg"></img>
              <div className="TiaImage2">
                <img src={TiaImage2} alt="prayer" className="img-fluid"></img>
              </div>
              <div className="EllipseBlue">
                <img src={EllipseBlue} alt="EllipseBlue" className="img-fluid"></img>
              </div>
              <div className="EllipseYellow">
                <img src={EllipseYellow} alt="EllipseYellow" className="img-fluid"></img>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TeachersInnovationAwards