import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './goodnumbers.css'
function GoodNumbers() {
  return (
    <>
      <section className="tiaGoodnumber">
        <Container fluid>
          <Row>
            <Col>
              <h3 className="title"> We're good with <span> numbers.</span> </h3>
              <p className="text">We are focused on results. Those that can be measured, and those measured in ways beyond numbers.</p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={2}>
              <div className="line">
                <h2 className="sub-title">1500<span>+</span> </h2>
                <span className="sub-text"> Innovative Teachers<br /> Honoured </span>
              </div>
            </Col>
            <Col lg={2}>
              <div className="line">
                <h2 className="sub-title"> 56 </h2>
                <span className="sub-text">Innovations <br /> Handbooks Released </span>
              </div>
            </Col>
            <Col lg={2}>
              <div className="line">
                <h2 className="sub-title"> 1500<span>+</span></h2>
                <span className="sub-text">Innovative Ideas<br /> Compiled </span>
              </div>
            </Col>
            <Col lg={2}>
              <div className="line">
                <h2 className="sub-title"> 03 </h2>
                <span className="sub-text">Annual Conferences<br /> and Award Ceremonies</span>
              </div>
            </Col>
            <Col lg={2}>
              <div className="line border-0">
                <h2 className="sub-title"> 444</h2>
                <span className="sub-text">Districts in 31<br /> States/UT</span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default GoodNumbers;