import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './Definition.css'
function Definition() {
  return (
    <section className="teacher-Banner">
      <Container >
        <Row>
          <Col>
            <h2 className='title'> Sri Aurobindo <span>defines 'Teaching'</span></h2>
            <p className="text">According to Sri Aurobindo, each child is like a seed that has innate potential to become a tree, and a teacher is like a gardner that nourishes the child with knowledge, wisdom, compassion and care. The role of a teacher is not just to impart education, but to cultivate in them a deeper understanding of how to use knowledge. This is the kind of teaching-learning that repeatedly brings teachers and students with India's age old value, culture, heritage and diversity, with an eye on the future possibilities.</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Definition