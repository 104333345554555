import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Header from "../../Header/Header";
import './Banner.css';
import Arrow from '../../Assets/arrow.svg';
function Banner() {
  return (
    <section className="homeBanner">
      <Header />
      <Container fluid>
        <Row>
          <Col>
            <div className="banner-content">
              <h2 className="sub-title">EFFECTIVE ZERO-INVESTMENT</h2>
              <h1 className="title">Innovative Teaching Methods</h1>
              <p className="text">Submit your idea for the Teacher Innovation Award</p>
              <a href="https://ziiei.com/teacherinnovationaward/" target="_blank" rel="noreferrer"><Button variant="yellow" className="banner-btn">Submit <img src={Arrow} className="ps-4 submitArrow img-fluid" alt="arrow"></img></Button></a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default Banner