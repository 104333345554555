import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './OurHandbooks.css'
// import RightArrow from '../../Assets/RightArrow.svg';
// import LeftArrow from '../../Assets/LeftArrow.svg';
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowRight } from 'react-icons/bs';
import Andaman from '../../Assets/NAVACHAARPUSTIKA Images/Andaman.png';
import Bihar from '../../Assets/NAVACHAARPUSTIKA Images/Bihar.png';
import Chandigarh from '../../Assets/NAVACHAARPUSTIKA Images/Chandigarh.png';
import Assam from '../../Assets/NAVACHAARPUSTIKA Images/Assam.png';
import Up from '../../Assets/NAVACHAARPUSTIKA Images/Up.png';
import Andhra from '../../Assets/NAVACHAARPUSTIKA Images/Andhra.png';
import ANDAMANANDNICOBAR2NDISSUE from '../../Assets/NAVACHAARPUSTIKA PDF/2019-2020/ANDAMAN AND NICOBAR 2ND ISSUE.pdf';
import ANDHRAPRADESHNAVACHARPUSTIKA1STISSUE from '../../Assets/NAVACHAARPUSTIKA PDF/2019-2020/ANDHRA PRADESH NAVACHAR PUSTIKA 1ST ISSUE.pdf';
import ASSAMNAVACHARPUSTIKA1STISSUE from '../../Assets/NAVACHAARPUSTIKA PDF/2019-2020/ASSAM NAVACHAR PUSTIKA 1ST ISSUE.pdf';
import BAHRAICHUPNAVACHARPUSTIKA1STISSUE from '../../Assets/NAVACHAARPUSTIKA PDF/2019-2020/BAHRAICH (UP) NAVACHAR PUSTIKA 1ST ISSUE.pdf';
import BIHARNAVACHARPUSTIKA2NDISSUE from '../../Assets/NAVACHAARPUSTIKA PDF/2019-2020/BIHAR NAVACHAR PUSTIKA 2ND ISSUE.pdf';
import CHANDIGARHNavacharpustikaISSUE2ND from '../../Assets/NAVACHAARPUSTIKA PDF/2019-2020/CHANDIGARH Navachar pustika ISSUE 2ND.pdf';

function OurHandbooks() {


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1200 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1200, min: 768 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  function CustomRightArrow({ onClick }) {
    function handleClick() {
      // // do whatever you want on the right button click
      // // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      // <img src={RightArrow}
      //   onClick={handleClick}
      //   aria-label="Go to next slide"
      //   className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      // />
      <BsArrowRight
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      />
    );
  }

  function CustomLeftArrow({ onClick }) {
    function handleClick() {
      // // do whatever you want on the right button click
      // // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      // <img src={LeftArrow}
      //   onClick={handleClick}
      //   aria-label="Go to next slide"
      //   className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      // />
      <BsArrowLeft
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      />
    );
  }

  return (
    <section className='OurHandbooks'>
      <Container>
        <Row>
          <Col>
            <div className=''>
              <h2 className='title'>Our Handbooks</h2>
            </div>
            <Carousel itemClass="OurHandbooksCarousel"
              responsive={responsive}
              infinite
              ssr={true}
              arrows
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
              keyBoardControl
            >
              <div className='content'>
                <a href={ANDAMANANDNICOBAR2NDISSUE} target="_blank" rel="noreferrer">
                  <img src={Andaman} className="img-fluid" alt="" />
                  <h2 className='stateName'>ANDAMAN AND NICOBAR</h2>
                  <p className='year'>2019 - 2020</p>
                </a>
              </div>
              <div className='content'>
                <a href={ANDHRAPRADESHNAVACHARPUSTIKA1STISSUE} target="_blank" rel="noreferrer">
                  <img src={Andhra} className="img-fluid" alt="" />
                  <h2 className='stateName'>ANDHRA PRADESH</h2>
                  <p className='year'>2019 - 2020</p>
                </a>
              </div>
              <div className='content'>
                <a href={ASSAMNAVACHARPUSTIKA1STISSUE} target="_blank" rel="noreferrer">
                  <img src={Assam} className="img-fluid" alt="" />
                  <h2 className='stateName'>ASSAM</h2>
                  <p className='year'>2019 - 2020</p>
                </a>
              </div>
              <div className='content'>
                <a href={BAHRAICHUPNAVACHARPUSTIKA1STISSUE} target="_blank" rel="noreferrer">
                  <img src={Up} className="img-fluid" alt="" />
                  <h2 className='stateName'>BAHRAICH (UP)</h2>
                  <p className='year'>2019 - 2020</p>
                </a>
              </div>
              <div className='content'>
                <a href={BIHARNAVACHARPUSTIKA2NDISSUE} target="_blank" rel="noreferrer">
                  <img src={Bihar} className="img-fluid" alt="" />
                  <h2 className='stateName'>BIHAR</h2>
                  <p className='year'>2019 - 2020</p>
                </a>
              </div>
              <div className='content'>
                <a href={CHANDIGARHNavacharpustikaISSUE2ND} target="_blank" rel="noreferrer">
                  <img src={Chandigarh} className="img-fluid" alt="" />
                  <h2 className='stateName'>CHANDIGARH</h2>
                  <p className='year'>2019 - 2020</p>
                </a>
              </div>


            </Carousel>

          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OurHandbooks