import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './goodnumbers.css';
function GoodNumbers() {
	return (
		<>
			<section className="aboutGoodnumber">
				<Container fluid>
					<Row>
						<Col>
							<h2 className="title">We're good with <span>numbers</span></h2>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col lg={2}>
							<div className="line">
								<h2 className="sub-title">1500<span>+</span></h2>
								<span className="text">Innovative Teachers <br />Honoured</span>
							</div>
						</Col>
						<Col lg={2}>
							<div className="line">
								<h2 className="sub-title">56</h2>
								<span className="text">Innovations  <br /> Handbooks Released</span>
							</div>
						</Col>
						<Col lg={2}>
							<div className="line">
								<h2 className="sub-title">1500<span>+</span></h2>
								<span className="text">Innovative Ideas <br /> Compiled</span>
							</div>
						</Col>
						<Col lg={2}>
							<div className="line">
								<h2 className="sub-title">03</h2>
								<span className="text">Annual Conferences <br /> and Award Ceremonies</span>
							</div>
						</Col>
						<Col lg={2}>
							<div className="line border-0">
								<h2 className="sub-title">2.2</h2>
								<span className="text">	Million Teachers <br />Oriented	</span>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	)
}
export default GoodNumbers;