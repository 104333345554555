import React from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Testimonials.css';
// import RightArrow from '../../Assets/RightArrow.svg'
// import LeftArrow from '../../Assets/LeftArrow.svg'
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowRight } from 'react-icons/bs';
import MVenkaiahNaidu from '../../Assets/testimonial/M-Venkaiah-Naidu.png';
import RameshPokhriyal from '../../Assets/testimonial/Ramesh-Pokhriyal.png';
import PrakashJavadekar from '../../Assets/testimonial/Prakash-Javadekar.png';
import YogiAdityanath from '../../Assets/testimonial/Yogi-Adityanath.png';
import DraupadiMurmu from '../../Assets/testimonial/Draupadi-Murmu.png';
import BanwantilalPurohit from '../../Assets/testimonial/Banwantilal-Purohit.png';
import RajnathSingh from '../../Assets/testimonial/Rajnath-Singh.png';
import Quotes from '../../Assets/quotes.svg';

function Testimonials() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  function CustomRightArrow({ onClick }) {
    function handleClick() {
      // // do whatever you want on the right button click
      // // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      // <img src={RightArrow}
      //   onClick={handleClick}
      //   aria-label="Go to next slide"
      //   className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      // />
      <BsArrowRight
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      />
    );
  }

  function CustomLeftArrow({ onClick }) {
    function handleClick() {
      // // do whatever you want on the right button click
      // // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      // <img src={LeftArrow}
      //   onClick={handleClick}
      //   aria-label="Go to next slide"
      //   className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      // />
      <BsArrowLeft
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      />
    );
  }


  return (
    <section className="testimonials">
      <Container fluid>
        <Row>
          <Col>
            <h2 className="title">Testimonials</h2>
            <Carousel itemClass="testimonialCarousel"
              responsive={responsive}
              infinite
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
            >
              <Card className="mb-3">
                <Row className="align-items-center">
                  <Col lg="4">
                    <Card.Img src={MVenkaiahNaidu} />
                  </Col>
                  <Col lg="8">
                    <Card.Body>
                      <Card.Img src={Quotes} className="quotesImage" />
                      <Card.Text>Teachers lead students on the path of becoming self-awaked and self-reliant. I am glad to know that Sri Aurobindo Society education initiative ZIIEI is giving the confidence to the teachers to experiment with new ideas.</Card.Text>
                      <Card.Title>Shri M. Venkaiah Naidu</Card.Title>
                      <Card.Subtitle>Former Vice President of India</Card.Subtitle>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
              <Card className="mb-3">
                <Row className="align-items-center">
                  <Col lg="4">
                    <Card.Img src={RajnathSingh} />
                  </Col>
                  <Col lg="8">
                    <Card.Body>
                      <Card.Img src={Quotes} className="quotesImage" />
                      <Card.Text>It is heartening to know that Sri Aurobindo Society is bringing much needed transformation in the education by identifying and promoting the existing best practices in education and emphasising on holistic development of a student.</Card.Text>
                      <Card.Title>Shri Rajnath Singh</Card.Title>
                      <Card.Subtitle>Hon'ble Minister of Defence</Card.Subtitle>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
              <Card className="mb-3">
                <Row className="align-items-center">
                  <Col lg="4">
                    <Card.Img src={RameshPokhriyal} />
                  </Col>
                  <Col lg="8">
                    <Card.Body>
                      <Card.Img src={Quotes} className="quotesImage" />
                      <Card.Text>The need for ZIIEI chosen teachers will go in to inspire all those teachers who have been teaching innovatively but have remained to just their own school until now.</Card.Text>
                      <Card.Title>Dr. Ramesh Pokhriyal</Card.Title>
                      <Card.Subtitle>Minister of Human Resource Development</Card.Subtitle>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
              <Card className="mb-3">
                <Row className="align-items-center">
                  <Col lg="4">
                    <Card.Img src={PrakashJavadekar} />
                  </Col>
                  <Col lg="8">
                    <Card.Body>
                      <Card.Img src={Quotes} className="quotesImage" />
                      <Card.Text>The need for information and knowledge has constantly evolved over the years, and thus, micro-innovation in education, such as ZIIEI, stands tall among other problem-solving approaches.</Card.Text>
                      <Card.Title>Shri Prakash Javadekar</Card.Title>
                      <Card.Subtitle>Former Minister of Environment, Forest and Climate Change</Card.Subtitle>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
              <Card className="mb-3">
                <Row className="align-items-center">
                  <Col lg="4">
                    <Card.Img src={YogiAdityanath} />
                  </Col>
                  <Col lg="8">
                    <Card.Body>
                      <Card.Img src={Quotes} className="quotesImage" />
                      <Card.Text>Implementation of ZIIEI in Uttar Pradesh is a commendable effort. I am hopeful that Sri Aurobindo Society will play a significant role in creating a bright future for the students of the state.</Card.Text>
                      <Card.Title>Shri Yogi Adityanath</Card.Title>
                      <Card.Subtitle>Hon'ble Chief Minister, Uttar Pradesh</Card.Subtitle>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
              <Card className="mb-3">
                <Row className="align-items-center">
                  <Col lg="4">
                    <Card.Img src={DraupadiMurmu} />
                  </Col>
                  <Col lg="8">
                    <Card.Body>
                      <Card.Img src={Quotes} className="quotesImage" />
                      <Card.Text>Efforts have been made to ensure for the tribal population, who generally are deprived of it. The zero investment based ideas in the ZIIEI program ensure that education is imparted until the grass root levels.</Card.Text>
                      <Card.Title>Smt. Draupadi Murmu</Card.Title>
                      <Card.Subtitle>Hon'ble President of India</Card.Subtitle>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
              <Card className="mb-3">
                <Row className="align-items-center">
                  <Col lg="4">
                    <Card.Img src={BanwantilalPurohit} />
                  </Col>
                  <Col lg="8">
                    <Card.Body>
                      <Card.Img src={Quotes} className="quotesImage" />
                      <Card.Text>The improvement in attendance, enrolment and learning outcomes  that are becoming evident in other states where ZIIEI is operational are proof that today's solutions by teachers can be a game-changing factor in education.</Card.Text>
                      <Card.Title>Shri Banwantilal Purohit</Card.Title>
                      <Card.Subtitle>Governor of Punjab, India and Administrator of Chandigarh</Card.Subtitle>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Testimonials
