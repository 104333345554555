import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Header from "../../Header/Header";
import './Banner.css';
import Arrow from '../../Assets/arrow.svg';
function Banner() {
  return (
    <section className="tiaBanner">
      <Header />
      <Container fluid>
        <Row className="justify-content-center">
          <Col xxl="5" lg="6">
            <div className="banner-content">
              <h1 className="title">TIA Award</h1>
              <p className="text">A national-level award for the teachers to salute their examplary grassroot zero-investment innovations.</p>
              <a href="https://ziiei.com/teacherinnovationaward/" target="_blank" rel="noreferrer"><Button variant="yellow" className="banner-btn">Submit <img src={Arrow} className="ps-4" alt="arrow"></img></Button></a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default Banner