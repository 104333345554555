import React from 'react'
import {Container,Col,Row} from 'react-bootstrap';
import './Timeline.css';
import NewImage from '../../Assets/timeline.svg'
function Timeline() {
  return (
    <section className='storyTimeline'>
      <Container fluid>
        <Row>
          <Col>
            <h2 className='title'>Timeline</h2>
           <img src={NewImage} className="img-fluid" alt='timeline'/>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Timeline